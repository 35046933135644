import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { Auth, API, Analytics } from 'aws-amplify';
import config from './config';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/material-dashboard-react.css?v=1.2.0';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import store from './store/index';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#fff',
      dark: '#002884',
      contrastText: '#000000de'
    },
    secondary: {
      light: '#333',
      main: '#666',
      dark: '#999',
      contrastText: '#ccc'
    }
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        button: {
          cursor: 'pointer',
          '&:active': {
            color: '#fff'
          },
          selected: {
            color: 'red  !important'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&:active': {
            color: 'blue'
          },
          marginRight: 0,
          color: '#b4b4b4'
        }
      }
    }
  }
});

let setupConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'root',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  },
  Analytics: {
    // OPTIONAL -  Amazon Pinpoint App Client ID
    appId: config.analytics.appId,
    // OPTIONAL -  Amazon service region
    region: config.analytics.region,
    // OPTIONAL - disable Analytics if true
    disabled: true
  }
};

console.log(setupConfig);

//Amplify.configure(setupConfig);
Auth.configure(setupConfig.Auth);
// Storage.configure(setupConfig.Storage);
API.configure(setupConfig.API);
Analytics.configure(setupConfig.Analytics);

ReactDOM.render(
  <Router>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Router>,
  document.getElementById('root')
);
//registerServiceWorker();
