import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import './App.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { privateListItems } from './components/SiteLinks';
import Routes from './Routes';
import { Auth, Analytics } from 'aws-amplify';
import config from './config';
import NotificationBar from './NotificationBar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import { newMqttConnection } from './actions/';
import short from 'short-uuid';
import Logo from './components/Logo';
import MenuIcon from '@mui/icons-material/Menu';
import withMediaQuery from './utils/withMediaQuery';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`,
  appBarFull: `${PREFIX}-appBarFull`,
  appBarMobile: `${PREFIX}-appBarMobile`,
  appBarFullScreen: `${PREFIX}-appBarFullScreen`,
  appBarButton: `${PREFIX}-appBarButton`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  contentFullScreen: `${PREFIX}-contentFullScreen`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex'
  },

  [`& .${classes.appBarFull}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
  },

  [`& .${classes.appBarMobile}`]: {
    width: `100%`,
    marginLeft: drawerWidth,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
  },

  [`& .${classes.appBarFullScreen}`]: {
    width: `100%`,
    marginLeft: drawerWidth,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
  },

  [`& .${classes.appBarButton}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth
  },

  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },

  [`& .${classes.contentFullScreen}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  }
}));

const mapDispatchToProps = (dispatch: any) => {
  return {
    newMqttConnection: (credentails: any) => dispatch(newMqttConnection(credentails))
  };
};

const drawerWidth = 251;
interface IState {
  isAuthenticated: boolean;
  showNotification: boolean;
  isAuthenticating: boolean;
  userPermissions: any[];
  userPermittedPaths: any[];
  userDefaultPath: string;
  left: boolean;
  anchorEl: any;
  isFullScreen: boolean;
  mouseX: any;
  mouseY: any;
}
export class App extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isAuthenticated: false,
      showNotification: true,
      isAuthenticating: true,
      userPermissions: [],
      userPermittedPaths: [],
      userDefaultPath: '',
      left: false,
      anchorEl: null,
      isFullScreen: false,
      mouseX: null,
      mouseY: null
    };
  }

  //Load User Session
  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        let sessionDetails = await Auth.currentCredentials();
        let client_id = sessionDetails.data.IdentityId + '.' + short.uuid();

        this.userHasAuthenticated(true, ['*'], ['*'], '/');

        if (config.setting.STAGE !== 'test' && config.setting.STAGE !== 'prod') {
          console.log({
            accessKeyId: sessionDetails.accessKeyId,
            secretAccessKey: sessionDetails.secretAccessKey,
            sessionToken: sessionDetails.sessionToken,
            service: 'execute-api',
            region: 'us-west-2'
          });
        }

        let mqttClientCredentials = {
          clientId: client_id,
          accessKeyId: sessionDetails.accessKeyId,
          secretAccessKey: sessionDetails.secretAccessKey,
          sessionToken: sessionDetails.sessionToken,
          endpointAddress: config.aws_pubsub_endpoint.IOT_END_POINT,
          region: config.aws_pubsub_endpoint.REGION
        };

        let topics = {
          rsp: config.setting.STAGE + '/' + sessionDetails.data.IdentityId + '/rsp',
          cag: config.setting.STAGE + '/cag/#'
        };

        this.props.newMqttConnection({ config: mqttClientCredentials, topics: topics });
      }
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  toggleDrawer = (side: string, open: boolean) => {
    console.log(side, open);
    this.setState({
      [side]: open
    });
  };

  userHasAuthenticated = (authenticated: boolean, userPermissions: any[], userPermittedPaths: any[], userDefaultPath: string) => {
    this.setState({
      isAuthenticated: authenticated,
      userPermissions: [...userPermissions],
      userPermittedPaths: [...userPermittedPaths],
      userDefaultPath: userDefaultPath
    });
  };

  setUserPermissions = (permissions: any) => {
    this.setState({
      userPermissions: [...permissions, ...this.state.userPermissions]
    });
  };

  setUserPermittedPaths = (path: any) => {
    this.setState({ userPermittedPaths: path });
  };

  setUserDefaultPath = (path: string) => {
    this.setState({ userDefaultPath: path });
  };

  handleProfile = async () => {
    this.setState({ anchorEl: null });
    this.props.history.push('/profile');
  };

  handleLogout = async () => {
    //Load User Session
    await Auth.signOut();
    await Analytics.stopSession();
    this.userHasAuthenticated(false, [], [], '');
    this.setState({ anchorEl: null });
    this.props.history.push('/login');
  };

  toggleisFullScreen = () => {
    const { isFullScreen } = this.state;
    this.setState({
      isFullScreen: !isFullScreen,
      anchorEl: null,
      mouseX: null,
      mouseY: null
    });
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUserPermissions: this.setUserPermissions,
      setUserPermittedPaths: this.setUserPermittedPaths,
      setUserDefaultPath: this.setUserDefaultPath,
      userPermissions: this.state.userPermissions,
      userPermittedPaths: this.state.userPermittedPaths,
      userDefaultPath: this.state.userDefaultPath
    };

    const sideList = (
      <div className='list'>
        {this.state.isAuthenticated ? (
          <Fragment>
            {' '}
            <List>{privateListItems(childProps)}</List>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    );
    const open = Boolean(this.state.anchorEl);
    const { isMobile } = this.props;
    const { left } = this.state;

    const isFullScreen = window.location.pathname === '/fullscreen';
    return (
      !this.state.isAuthenticating && (
        <Root className={classes.root}>
          {!isFullScreen ? (
            <AppBar position='fixed' className={isMobile ? classes.appBarMobile : classes.appBarFull}>
              <Toolbar>
                {isMobile ? (
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={() => this.toggleDrawer('left', true)}
                    edge='start'
                    className={classes.appBarButton}
                    size='large'
                  >
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <Fragment></Fragment>
                )}
                <Typography variant='h6' color='inherit'>
                  <Logo />
                </Typography>
                {this.state.isAuthenticated ? (
                  <Fragment>
                    <IconButton aria-haspopup='true' onClick={(event) => this.setState({ anchorEl: event.currentTarget })} color='inherit' size='large'>
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id='menu-appbar'
                      anchorEl={this.state.anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      PaperProps={{
                        style: {
                          width: 200
                        }
                      }}
                      open={open}
                      onClose={() => this.setState({ anchorEl: null, mouseX: null, mouseY: null })}
                    >
                      <MenuItem onClick={this.toggleisFullScreen}>Enter Full Screen</MenuItem>
                      <Divider />
                      <MenuItem onClick={this.handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                    </Menu>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button color='inherit' component={Link} to='/login'>
                      Login
                    </Button>
                  </Fragment>
                )}
              </Toolbar>
            </AppBar>
          ) : (
            <div />
          )}
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            anchor='left'
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper
            }}
            open={isMobile ? left : true}
            onClose={() => this.toggleDrawer('left', false)}
          >
            <div tabIndex={0} role='button'>
              {sideList}
            </div>
          </Drawer>

          <main className={classes.content}>
            <div style={isMobile ? { marginLeft: 0 } : { marginLeft: drawerWidth }}>
              <div className={classes.toolbar} />
              <Routes childProps={childProps} />
            </div>
          </main>
          <NotificationBar />
        </Root>
      )
    );
  }
}

const AppPage = connect(null, mapDispatchToProps)(App);
//export default (AppPage);
export default withMediaQuery()(AppPage);
