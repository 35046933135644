/*
The search feature will likely be a bit wonky. I hide columns from view rather than removing them.
This results in their hidden content also being search on.

The reason I used this approach rather than removing the columns was a bug in primereact's code.
This bug would prevent columns from being added after a reorder operation was executed.
*/

import React from 'react';
import { styled } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import store from '../../../store/index';
import { saveDeviceInventory, setDrawerMenuItem } from '../../../actions/index';
import PagableTable from '../../../components/PagableTable';
import { IState } from '../../../reducers';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3)
  },

  [`& .${classes.table}`]: {
    minWidth: 1020
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block'
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none'
  },

  [`&.${classes.divBg}`]: {
    padding: 25
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35
  }
}));

const mapStateToProps = (state: IState) => {
  return {
    deviceInventory: state.deviceInventory
  };
};

class EnhancedTable extends React.Component<
  any,
  {
    cols: any[];
    loading: boolean;
    selectedCols: any[];
    anchorEl: any;
    IdentityId: any;
    userEmail: string;
  }
> {
  state = {
    cols: [],
    loading: true,
    selectedCols: [],
    anchorEl: null,
    IdentityId: null,
    userEmail: ''
  };

  async componentDidMount() {
    store.dispatch(setDrawerMenuItem('devices'));
    try {
      let sessionDetails = await Auth.currentCredentials();
      const profile = await Auth.currentAuthenticatedUser();
      this.setState({
        IdentityId: sessionDetails.data.IdentityId,
        userEmail: profile.username
      });
    } catch {
      alert('inventory device error');
    }
  }

  render() {
    const { deviceInventory } = this.props;
    console.log(deviceInventory);

    const selectedColumns = ['Barcode', 'App Fw', 'Part Number', 'Revision', 'Gateway', 'Stage'];
    const columnHeaders = [
      { field: 'barcode', header: 'Barcode' },
      { field: 'xbeeAppFw', header: 'App Fw' },
      { field: 'xbeeRadioFw', header: 'Radio Fw' },
      { field: 'xbeeMacAddr', header: 'MAC' },
      { field: 'partNumber', header: 'Part Number' },
      { field: 'revision', header: 'Revision' },
      { field: 'cagBarcode', header: 'Gateway' },
      { field: 'comment', header: 'Comment' },
      { field: 'stage', header: 'Stage' }
    ];

    return (
      <Root className={classes.divBg}>
        <PagableTable
          endpoint='logging/devices'
          tableName='Device Inventory'
          columnsSelected={selectedColumns}
          columnHeaders={columnHeaders}
          defaultData={deviceInventory}
          saveAction={saveDeviceInventory}
          links={{
            barcode: {
              destination: 'devices'
            },
            cagBarcode: {
              destination: 'gateways'
            }
          }}
        />
      </Root>
    );
  }
}

const List = connect(mapStateToProps)(EnhancedTable);
export default List;
