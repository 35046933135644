import React, { Fragment } from 'react';

function WithUserPermissions(WrappedComponent: any, componentPermissionRequired: any) {
  return class WithFoo extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
    }

    render() {
      const { props } = this.props;
      const isUserPermitted = props.userPermissions.includes(componentPermissionRequired) || props.userPermissions.includes('*');
      return <Fragment>{isUserPermitted ? <WrappedComponent {...this.props} /> : ''}</Fragment>;
    }
  };
}

export default WithUserPermissions;
