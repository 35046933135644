import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import wedgeLogo from '../assets/img/wedge_logo.svg';

const PREFIX = 'Logo';

const classes = {
  container: `${PREFIX}-container`,
  image: `${PREFIX}-image`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 48,
    paddingLeft: 24,
    marginTop: 48,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16
    }
  },

  [`& .${classes.image}`]: {
    width: 123.67,
    height: 28
  }
}));

function Logo() {
  return (
    <Root>
      <ButtonBase component={Link} to='/' disableRipple>
        <img className={classes.image} src={wedgeLogo} alt='WEDGE logo' />
      </ButtonBase>
    </Root>
  );
}

export default Logo;
