class MQTTRedux {
  config: any;
  mqtt: any;
  topicActionMap: any;
  constructor(config: any) {
    this.config = config;
    //this.mqtt = connect(config.url, config.opt);
    // this.mqtt = new AWSMqtt(config);

    this.topicActionMap = {};
  }
  publish(payload: any, store: any) {}
  subscribe_to_topic(payload: any, store: any) {}
  connect(actions: any, store: any) {}
  subscribe(actionMap: any) {}
  unsubscribe(actions: any) {}
  createMiddleware() {}
}
export function createClient(config: any) {
  return new MQTTRedux(config);
}
