import {
  SUBSCRIBE_TO_TOPIC,
  NEW_NOTIFICATION_MESSAGE,
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  CONNECT_TO_MQTT,
  SUBSCRIBE_TO_DEFAULT_TOPIC,
  PUBLISH_TO_MQTT,
  SAVE_CAG_INVENTORY,
  SAVE_DEVICE_INVENTORY,
  SAVE_SITE_LIST,
  SAVE_ERROR_LIST,
  SAVE_KPI,
  SET_DRAWER_MENU_ITEM
} from '../constants/action-types';

export const newNotificationMessage = (message: any) => ({
  type: NEW_NOTIFICATION_MESSAGE,
  payload: message
});
export const newMqttConnection = (userConnectionCredentails: any) => ({
  type: CONNECT_TO_MQTT,
  payload: userConnectionCredentails
});
export const subscribeToDefaultTopic = (topic: any) => ({
  type: SUBSCRIBE_TO_DEFAULT_TOPIC,
  payload: topic
});
export const openNotification = (flag: any) => ({
  type: OPEN_NOTIFICATION,
  payload: flag
});
export const closeNotification = (flag: boolean) => ({
  type: CLOSE_NOTIFICATION,
  payload: flag
});
export const publishToTopic = (message: any) => ({
  type: PUBLISH_TO_MQTT,
  payload: message
});
export const subscribeToTopic = (topic: any) => ({
  type: SUBSCRIBE_TO_TOPIC,
  payload: topic
});
export const saveCagInventory = (inventoryArray: any) => ({
  type: SAVE_CAG_INVENTORY,
  payload: inventoryArray
});
export const saveDeviceInventory = (inventoryArray: any) => ({
  type: SAVE_DEVICE_INVENTORY,
  payload: inventoryArray
});
export const saveSiteList = (siteList: any) => ({
  type: SAVE_SITE_LIST,
  payload: siteList
});
export const saveErrorList = (errorList: any) => ({
  type: SAVE_ERROR_LIST,
  payload: errorList
});
export const saveKPI = (kpi: any) => ({
  type: SAVE_KPI,
  payload: kpi
});
export const setDrawerMenuItem = (value: string) => ({
  type: SET_DRAWER_MENU_ITEM,
  payload: value
});
