import React from 'react';
import { styled } from '@mui/material/styles';
import { POST_ENDPOINT } from '../../../api-request';
import { SSH_COMMAND } from '../../../constants/end-points';
import Grid from '@mui/material/Grid';
import store from '../../../store/index';
import { setDrawerMenuItem } from '../../../actions/index';
import PagableTable from '../../../components/PagableTable';
import PagableTableWithDataDisplay from '../../../components/PagableTableWithDataDisplay';
import ReactJson from 'react-json-view';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PREFIX = 'gateway-details';

const classes = {
  root: `${PREFIX}-root`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`,
  textBlock: `${PREFIX}-textBlock`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    height: 510,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative'
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },

  [`& .${classes.textBlock}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: 0
  }
}));

class CagDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      count: 0,
      deviceStateArray: [],
      text: 'Hello World!',
      command: 'uptime',
      cagStatus: 'Searching...',
      pingLoading: false,
      rebootLoading: false,
      barcode: '',
      open: false
    };
  }

  async componentDidMount() {
    console.log('a');

    const barcode = window.location.pathname.split('/')[2];
    this.setState({ barcode, loading: false });

    store.dispatch(setDrawerMenuItem('gateways'));
  }

  handleCommandIssue = async () => {
    let response = await POST_ENDPOINT(SSH_COMMAND, {
      ipAddress: this.state.identity.ipAddress,
      command: this.state.command
    });
    this.setState({
      text: response.out
    });
  };

  handlePing = async () => {
    this.setState({
      pingLoading: true
    });
    try {
      let response = await POST_ENDPOINT(SSH_COMMAND, {
        ipAddress: this.state.identity.ipAddress,
        command: 'uptime'
      });
      this.setState({
        text: response.out,
        cagStatus: response.code === 0 ? 'Online' : 'Offline',
        pingLoading: false
      });
    } catch (e) {
      this.setState({
        pingLoading: false
      });
    }
  };

  handleReboot = async () => {
    console.log('Identity', this.state.barcode);

    this.setState({
      rebootLoading: true,
      open: false
    });
    try {
      let response = await POST_ENDPOINT(SSH_COMMAND, {
        barcode: this.state.barcode,
        command: 'sudo reboot'
      });
      this.setState({
        text: response.code === null ? 'Rebooted' : 'An error occured',
        rebootLoading: false
      });
    } catch (e) {
      this.setState({
        text: 'An error occured',
        rebootLoading: false
      });
    }
  };

  renderCagDoc = (source: any) => {
    if (source === '') {
      return <Root style={{ textAlign: 'center', height: '100%', paddingTop: '45%' }}>Select a row from the table</Root>;
    } else {
      return <ReactJson displayDataTypes={false} src={source} name={source.barcode} />;
    }
  };

  uptimeTemplate(rowData: any[], column: any) {
    const data = rowData[column.field] ? rowData[column.field] : '';
    const formatTimeSpan = (time: number) => {
      const years = Math.floor(time / 3.154e7);
      const months = Math.floor((time % 3.154e7) / 2.628e6);
      const days = Math.floor((time % 2.628e6) / 8.64e4);
      const hours = Math.floor((time % 8.64e4) / 3.6e3);
      const minutes = Math.floor((time % 3.6e3) / 60);
      const seconds = time % 60;
      if (years) {
        return years + ' yr ' + months + ' mo';
      } else if (months) {
        return months + ' mo ' + days + ' day';
      } else if (days) {
        return days + ' day ' + hours + ' hr';
      } else if (hours) {
        return hours + ' hr ' + minutes + ' min';
      } else if (minutes) {
        return minutes + 'min ' + seconds + ' sec';
      } else {
        return seconds + ' sec';
      }
    };
    return <span>{formatTimeSpan(data)}</span>;
  }

  delayTemplate(rowData: any[], column: any) {
    const data = rowData[column.field] ? rowData[column.field] : '';
    const formatTimeSpan = (time: number) => {
      const months = Math.floor(time / 2.628e9);
      const days = Math.floor((time % 2.628e9) / 8.64e7);
      const hours = Math.floor((time % 8.64e7) / 3.6e6);
      const minutes = Math.floor((time % 3.6e6) / 60000);
      const seconds = Math.floor((time % 60000) / 1000);
      const milliseconds = time % 1000;
      if (months) {
        return months + ' mo ' + days + ' day';
      } else if (days) {
        return days + ' day ' + hours + ' hr';
      } else if (hours) {
        return hours + ' hr ' + minutes + ' min';
      } else if (minutes) {
        return minutes + ' min ' + seconds + ' sec';
      } else if (seconds) {
        return seconds + '.' + milliseconds + ' sec';
      } else {
        return milliseconds + 'ms';
      }
    };
    return <span>{formatTimeSpan(data)}</span>;
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { loading, barcode, rebootLoading, open } = this.state;
    const {} = this.props;

    const normalizedSelectedColumns = ['Timestamp', 'Duration', 'Uptime', 'Device Count'];
    const normalizedColumnHeaders = [
      { field: 'timestamp', header: 'Timestamp' },
      { field: 'timeReceived', header: 'Time Received' },
      { field: 'duration', header: 'Duration' },
      { field: 'uptime', header: 'Uptime' },
      { field: 'deviceCount', header: 'Device Count' },
      { field: 'sensorCount', header: 'Sensor Count' }
    ];
    const normalizedSearchEnabledColumns = ['timestamp', 'timeReceived', 'duration'];
    const normalizedTemplates = {
      uptime: this.uptimeTemplate
    };

    const rawSelectedColumns = ['Timestamp', 'Reporting Interval', 'Uptime', 'Device Count', 'Transmit'];
    const rawColumnHeaders = [
      { field: 'timestamp', header: 'Timestamp' },
      { field: 'timeReceived', header: 'Time Received' },
      { field: 'reportingInterval', header: 'Reporting Interval' },
      { field: 'uptime', header: 'Uptime' },
      { field: 'deviceCount', header: 'Device Count' },
      { field: 'sensorCount', header: 'Sensor Count' },
      { field: 'transmissionDelay', header: 'Transmit' }
    ];
    const rawSearchEnabledColumns = ['timestamp', 'timeReceived', 'reportingInterval'];
    const rawTemplates = {
      uptime: this.uptimeTemplate,
      transmissionDelay: this.delayTemplate
    };

    const inventorySelectedColumns = ['Radio Fw', 'App Version', 'Network ID', 'Site', 'Time Updated'];
    const inventoryColumnHeaders = [
      { field: 'timeCreated', header: 'Time Updated' },
      { field: 'xbeeRadioFw', header: 'Radio Fw' },
      { field: 'partNumber', header: 'Part Number' },
      { field: 'revision', header: 'Revision' },
      { field: 'ipAddress', header: 'IP Address' },
      { field: 'swVersion', header: 'App Version' },
      { field: 'xbeeNetworkId', header: 'Network ID' },
      { field: 'xbeeMacAddr', header: 'Xbee MAC' },
      { field: 'bbMacAddr', header: 'BB MAC' },
      { field: 'bbSerialNo', header: 'BB Serial No.' },
      { field: 'bbVersion', header: 'BB Version' },
      { field: 'iccid', header: 'ICCID' },
      { field: 'imei', header: 'IMEI' },
      { field: 'ubootVersion', header: 'UBoot Version' },
      { field: 'siteName', header: 'Site' },
      { field: 'stage', header: 'Stage' }
    ];

    if (loading) {
      return <div className={classes.root} />;
    } else {
      return (
        <div>
          <h3>
            Gateway Details: <strong>{barcode}</strong>{' '}
          </h3>
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <Button variant='contained' color='primary' disabled={rebootLoading} onClick={this.handleClickOpen}>
                Reboot
              </Button>
              {rebootLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
          <Dialog open={open} onClose={this.handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Reboot Gateway {barcode}?</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Rebooting a gateway may result in the loss of data. Are you sure you want to reboot Gateway {barcode}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleReboot} autoFocus>
                Reboot
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={1} justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <PagableTable
                endpoint={'logging/cags/' + barcode}
                tableName='Inventory History'
                columnsSelected={inventorySelectedColumns}
                columnHeaders={inventoryColumnHeaders}
                links={{
                  siteName: {
                    destination: 'sites',
                    translation: 'siteId'
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <PagableTableWithDataDisplay
                id={barcode}
                endpoint='logging/data/cag'
                tableName='Raw Cag Records'
                columnsSelected={rawSelectedColumns}
                columnHeaders={rawColumnHeaders}
                renderSource={this.renderCagDoc}
                searchEnabledColumns={rawSearchEnabledColumns}
                dataType='JSON'
                templates={rawTemplates}
              />
            </Grid>
            <Grid item xs={12}>
              <PagableTableWithDataDisplay
                id={barcode}
                endpoint='logging/data/normalized'
                tableName='Normalized Cag Records'
                columnsSelected={normalizedSelectedColumns}
                columnHeaders={normalizedColumnHeaders}
                renderSource={this.renderCagDoc}
                searchEnabledColumns={normalizedSearchEnabledColumns}
                dataType='JSON'
                templates={normalizedTemplates}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default CagDetails;
