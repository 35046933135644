import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface IProps {
  component: any;
  props: any;
  [x: string]: any;
}

const RounterComponent = ({ component: C, props: cProps, ...rest }: IProps) => {
  const isUserPermittedPath = cProps.userPermittedPaths.includes(rest.path) || cProps.userPermittedPaths.includes('*');
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          {cProps.isAuthenticated ? (
            <Fragment>
              {cProps.userPermittedPaths.length === 0 || cProps.userDefaultPath === '' ? (
                <Redirect to={`/no-permissions`} />
              ) : (
                <Fragment>{isUserPermittedPath ? <C {...props} {...cProps} /> : <Redirect to={`${cProps.userDefaultPath}`} />}</Fragment>
              )}
            </Fragment>
          ) : (
            <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
          )}
        </Fragment>
      )}
    />
  );
};

export default RounterComponent;
