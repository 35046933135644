import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import Profile from './containers/profile/profile';
import Gateway from './containers/inventory/gateway';
import GatewayDetails from './containers/inventory/gateway/gateway-details';
import Device from './containers/inventory/device';
import DeviceDetails from './containers/inventory/device/device-details';
import Users from './containers/users/';
import NotFound from './containers/NotFound';
import Login from './containers/login/login';
import NoPermissions from './containers/NoPermissions';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default ({ childProps }: { childProps: any }) => (
  <Switch>
    <AuthenticatedRoute path='/' exact component={Home} props={childProps} />
    <AuthenticatedRoute path='/gateways' exact component={Gateway} props={childProps} />
    <AuthenticatedRoute path='/gateways/:barcode' exact component={GatewayDetails} props={childProps} />
    <AuthenticatedRoute path='/devices' exact component={Device} props={childProps} />
    <AuthenticatedRoute path='/devices/:barcode' exact component={DeviceDetails} props={childProps} />
    <AuthenticatedRoute path='/users' exact component={Users} props={childProps} />
    <AuthenticatedRoute path='/fullscreen' exact component={Home} props={childProps} />
    <AuthenticatedRoute path='/profile' exact component={Profile} props={childProps} />
    <UnauthenticatedRoute path='/login' exact component={Login} props={childProps} />
    <NoPermissions path='/no-permissions' exact component={NoPermissions} props={childProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
