import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Auth, Analytics } from 'aws-amplify';
import LoaderButton from '../../components/LoaderButton';
import './login.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKey from '@mui/icons-material/VpnKey';

const PREFIX = 'login';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  textField: `${PREFIX}-textField`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200
  }
}));

//Login From UI Testing
class Login extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      isNewPassword: false,
      email: '',
      password: '',
      newPassword: '',
      userObject: {},
      name: ''
    };
  }

  validateForm() {
    return this.state.isNewPassword
      ? this.state.newPassword.length > 0 && this.state.email.length > 0 && this.state.password.length > 0
      : this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log('Submit');
    this.setState({ isLoading: true });
    if (this.state.isNewPassword) {
      try {
        await Auth.completeNewPassword(this.state.userObject, this.state.newPassword, {
          name: this.state.name
        });
        this.props.userHasAuthenticated(true, ['*'], ['*'], ['/']);
      } catch {
        alert('login completeNewPassword failed');
        this.setState({ isLoading: false });
        await Analytics.record('_userauth.auth_fail');
      }
    } else {
      try {
        let user = await Auth.signIn(this.state.email, this.state.password);
        if (user.challengeName !== undefined && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({ isNewPassword: true });
          this.setState({ isLoading: false });
          this.setState({ userObject: user });
        } else {
          this.props.userHasAuthenticated(true, ['*'], ['*'], '/');

          await Analytics.record('_userauth.sign_in');
          await Analytics.startSession();
        }

        //this.props.history.push("/");
      } catch {
        alert('login signin failed');
        this.setState({ isLoading: false });
        await Analytics.record('_userauth.auth_fail');
      }
    }
  };

  render() {
    return (
      <Root className='Login'>
        <form onSubmit={this.handleSubmit}>
          <div className={classes.container}>
            <FormControl variant='standard' fullWidth className={classes.formControl}>
              <InputLabel htmlFor='email'>Email</InputLabel>
              <Input
                id='email'
                value={this.state.email}
                onChange={this.handleChange}
                startAdornment={
                  <InputAdornment position='start'>
                    <AccountCircle color='disabled' />
                  </InputAdornment>
                }
              />
              <FormHelperText id='email-helper-text'>Enter Email</FormHelperText>
            </FormControl>
            <FormControl variant='standard' fullWidth className={classes.formControl}>
              <InputLabel htmlFor='password'>{this.state.isNewPassword ? 'Old' : ''} Password</InputLabel>
              <Input
                id='password'
                value={this.state.password}
                onChange={this.handleChange}
                type='password'
                startAdornment={
                  <InputAdornment position='start'>
                    <VpnKey color='disabled' />
                  </InputAdornment>
                }
              />
              <FormHelperText id='password-helper-text'>Enter {this.state.isNewPassword ? 'Old' : ''} Password</FormHelperText>
            </FormControl>

            {this.state.isNewPassword ? (
              <Fragment>
                <FormControl variant='standard' fullWidth className={classes.formControl}>
                  <InputLabel htmlFor='newPassword'>New Password</InputLabel>
                  <Input
                    id='newPassword'
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    type='password'
                    startAdornment={
                      <InputAdornment position='start'>
                        <VpnKey color='disabled' />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='password-helper-text'>Enter New Password</FormHelperText>
                </FormControl>

                <FormControl variant='standard' fullWidth className={classes.formControl}>
                  <InputLabel htmlFor='name'>Name</InputLabel>
                  <Input
                    id='name'
                    value={this.state.name}
                    onChange={this.handleChange}
                    startAdornment={
                      <InputAdornment position='start'>
                        <AccountCircle color='disabled' />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='name-helper-text'>Enter Full Name</FormHelperText>
                </FormControl>
              </Fragment>
            ) : (
              ''
            )}

            <FormControl variant='standard' className={classes.formControl}>
              <LoaderButton
                disabled={!this.validateForm()}
                type='submit'
                text={this.state.isNewPassword ? 'SET NEW PASSWORD' : 'LOGIN'}
                loadingText={this.state.isNewPassword ? 'Updating...' : 'Logging in…'}
                isLoading={this.state.isLoading}
              />
            </FormControl>
          </div>
        </form>
      </Root>
    );
  }
}

export default Login;
