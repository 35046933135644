export const CAN_VIEW_CONSOLE_DASHBOARD_LINK = "canViewConsoleDashboardLink";
export const CAN_VIEW_CONSOLE_MANUFACTURING_LINK = "canViewConsoleManufacturingLink";
export const CAN_VIEW_CONSOLE_GRAPH_LINK = "canViewConsoleGraphLink";
export const CAN_VIEW_CONSOLE_LOCATION_LINK = "canViewConsoleLocationLink";
export const CAN_VIEW_CONSOLE_FLOOR_PLANS_LINK = "canViewConsoleFloorPlansLink";
export const CAN_VIEW_CONSOLE_SENSOR_TYPES_LINK = "canViewConsoleSensorTypesLink";
export const CAN_VIEW_CONSOLE_USERS_LINK = "canViewConsoleUsersLink";
export const CAN_VIEW_CONSOLE_DEVICES_LINK = "canViewConsoleDevicesLink";
export const CAN_VIEW_HMC_DEMO_LINK = "canViewHmcDemoLink";
export const CAN_VIEW_CONSOLE_LOGGING_LINK = "canViewConsoleLoggingLink"
export const CAN_VIEW_CONSOLE_ALERT_LOG_LINK = "canViewConsoleAlertLogLink"
export const CAN_VIEW_CONSOLE_SITE_CONFIG_LOG_LINK = "canViewConsoleSiteConfigLogLink"
export const CAN_VIEW_CONSOLE_SITE_LIST_LINK = "canViewConsoleSiteListLink"
export const COMPONENT_TYPE_LINK = "Link";
export const COMPONENT_TYPE_PAGE = "Page";
