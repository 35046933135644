import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chartist from 'chartist';
import { DEV_COLOR, TEST_COLOR, PROD_COLOR } from '../assets/jss/material-dashboard-react';

const PREFIX = 'PieCard';

const classes = {
  graphStyle: `${PREFIX}-graphStyle`
};

const Root = styled('div')(() => ({
  [`& .${classes.graphStyle}`]: {
    '& .ct-line': { strokeWidth: '2px' },
    '& .ct-grids line': { stroke: 'grey' },
    '& .ct-labels span': { color: 'grey' },
    '& .ct-series-a .ct-line': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-line': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-line': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-bar': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-bar': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-bar': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-slice-donut': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-slice-donut': {
      stroke: '#efefef',
      opacity: 10.0
    },
    height: '100%'
  }
}));

interface IProps {
  icon: any;
  iconColor: 'orange' | 'green' | 'red' | 'blue' | 'purple';
  title: string;
  description: string;
  small: any;
  statIcon: any;
  statIconColor: 'warning' | 'primary' | 'danger' | 'success' | 'info' | 'rose' | 'gray';
  statLink: any;
  id: string;
  statText: string;
  assigned: number;
  total: number;
}
class PieCard extends Component<IProps, any> {
  static defaultProps: Partial<IProps> = {
    iconColor: 'purple',
    statIconColor: 'gray'
  };

  render() {
    const { title, assigned, total, id } = this.props;

    let data: any = {
      series: [assigned, total - assigned]
    };

    let options: any = {
      donut: true,
      donutWidth: 10,
      showLabel: false,
      height: '100px'
    };

    new Chartist.Pie('#' + id, data, options);

    return (
      <Root style={{ textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <Typography>{`${Math.round((assigned / total) * 100)} %`}</Typography>
        <div id={id} className={classes.graphStyle} />
      </Root>
    );
  }
}

export default PieCard;
