import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WithUserPermissions from '../../user-permissions-hoc/';
import Dashboard from '@mui/icons-material/AssessmentOutlined';
import { CAN_VIEW_CONSOLE_DASHBOARD_LINK } from '../../constants/permission-types';
import config from '../../config';
import { connect } from 'react-redux';
import store from '../../store/index';
import { setDrawerMenuItem } from '../../actions/index';
import { IState } from '../../reducers';

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu
  };
};

class DashboardLink extends React.Component<any, any> {
  render() {
    const { drawerActiveMenu } = this.props;

    return (
      <div>
        <span className='listTitle'>{config.setting.STAGE.toUpperCase()} CONSOLE</span>
        <ListItem dense button component={Link} to='/' selected={drawerActiveMenu === 'home'} onClick={() => store.dispatch(setDrawerMenuItem('home'))}>
          <ListItemIcon>
            <Dashboard className={drawerActiveMenu === 'home' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText secondary='Dashboard' className={drawerActiveMenu === 'home' ? 'activeLink' : 'nonactiveLink'} />
        </ListItem>
      </div>
    );
  }
}

const List = connect(mapStateToProps)(DashboardLink);
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_DASHBOARD_LINK);
