import defaultConfig from './config.json';

let CONFIG = defaultConfig;

console.log('DOMAIN :: ' + JSON.stringify(CONFIG.Url));
console.log('DEPLOYED STAGE :: ' + JSON.stringify(CONFIG.DeployedStage));

export default {
  setting: {
    STAGE: CONFIG.DeployedStage,
    REGION: CONFIG.AwsRegion
  },
  s3: {
    REGION: CONFIG.AwsRegion,
    BUCKET: CONFIG.WedgeConsoleS3BucketName
  },
  apiGateway: {
    REGION: CONFIG.AwsRegion,
    URL: CONFIG.Url
  },
  cognito: {
    REGION: CONFIG.AwsRegion,
    USER_POOL_ID: CONFIG.UserPoolId,
    APP_CLIENT_ID: CONFIG.ReactAppClientId,
    IDENTITY_POOL_ID: CONFIG.IdentityPoolId
  },
  analytics: {
    appId: '95bb26c37ba649ae8004836c1a21b707',
    region: 'us-east-1',
    disabled: false
  },
  aws_pubsub_endpoint: {
    IOT_END_POINT: CONFIG.IotEndPoint,
    REGION: CONFIG.AwsRegion
  }
};
