import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Auth } from 'aws-amplify';
import LoaderButton from '../../components/LoaderButton';
import VpnKey from '@mui/icons-material/VpnKey';

const PREFIX = 'profile';

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  textField: `${PREFIX}-textField`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200
  }
}));

//Login From UI Testing
class Login extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      isNewPassword: false,
      email: '',
      password: '',
      newPassword: '',
      userObject: {},
      name: ''
    };
  }

  validateForm() {
    return this.state.newPassword.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event: any) => {
    const { password, newPassword } = this.state;
    event.preventDefault();
    console.log('Submit');
    this.setState({ isLoading: true });
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, password, newPassword).then((response) => {
      console.log('RESPONSE', JSON.stringify(response));
      this.setState({
        isLoading: false
      });
    });
  };
  render() {
    const {} = this.props;

    return (
      <Root className='Login'>
        <form onSubmit={this.handleSubmit}>
          <div className={classes.container}>
            <FormControl variant='standard' fullWidth className={classes.formControl}>
              <InputLabel htmlFor='password'>Current Password</InputLabel>
              <Input
                id='password'
                value={this.state.password}
                onChange={this.handleChange}
                type='password'
                startAdornment={
                  <InputAdornment position='start'>
                    <VpnKey color='disabled' />
                  </InputAdornment>
                }
              />
              <FormHelperText id='password-helper-text'>Enter Current Password</FormHelperText>
            </FormControl>
            <Fragment>
              <FormControl variant='standard' fullWidth className={classes.formControl}>
                <InputLabel htmlFor='newPassword'>New Password</InputLabel>
                <Input
                  id='newPassword'
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  type='password'
                  startAdornment={
                    <InputAdornment position='start'>
                      <VpnKey color='disabled' />
                    </InputAdornment>
                  }
                />
                <FormHelperText id='password-helper-text'>Enter New Password</FormHelperText>
              </FormControl>
            </Fragment>

            <FormControl variant='standard' className={classes.formControl}>
              <LoaderButton
                disabled={!this.validateForm()}
                type='submit'
                text={'SET NEW PASSWORD'}
                // loadingText={this.state.isNewPassword ? "Updating..." : "Logging in…"}
                isLoading={this.state.isLoading}
              />
            </FormControl>
          </div>
        </form>
      </Root>
    );
  }
}

export default Login;
