import {
  SUBSCRIBE_TO_TOPIC,
  NEW_NOTIFICATION_MESSAGE,
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  CONNECT_TO_MQTT,
  SAVE_DEVICE_INVENTORY,
  SAVE_CAG_INVENTORY,
  SAVE_SITE_LIST,
  SAVE_ERROR_LIST,
  SAVE_KPI,
  SET_DRAWER_MENU_ITEM
} from '../constants/action-types';
import store from '../store/index';
import { createClient } from '../lib/mqttredux';

export interface IState {
  showNotification: boolean;
  newNotificationMessage: string;
  rspData: any;
  cagData: any;
  cagInventory: any;
  deviceInventory: any;
  siteList: any;
  errorList: any[];
  kpi: any;
  drawerActiveMenu: string;
}

const initialState: IState = {
  showNotification: false,
  newNotificationMessage: '',
  rspData: {},
  cagData: {},
  cagInventory: {},
  deviceInventory: {},
  siteList: {},
  errorList: [],
  kpi: {},
  drawerActiveMenu: 'home'
};

let mqttRedux: any;

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return { ...state, showNotification: action.payload };
    case CLOSE_NOTIFICATION:
      return { ...state, showNotification: action.payload };
    case NEW_NOTIFICATION_MESSAGE:
      return { ...state, newNotificationMessage: action.payload };
    case SAVE_CAG_INVENTORY:
      return { ...state, cagInventory: action.payload };
    case SAVE_DEVICE_INVENTORY:
      return { ...state, deviceInventory: action.payload };
    case SAVE_SITE_LIST:
      return { ...state, siteList: action.payload };
    case SAVE_ERROR_LIST:
      return { ...state, errorList: action.payload };
    case SAVE_KPI:
      return { ...state, kpi: action.payload };
    case SET_DRAWER_MENU_ITEM:
      return { ...state, drawerActiveMenu: action.payload };

    case 'SUBSCRIBE_TO_CAG':
      let cag_payload = action.payload;

      return {
        ...state,
        cagData: {
          payload: cag_payload,
          topic: action.topic
        }
      };

    case CONNECT_TO_MQTT:
      console.log('CONNECT_TO_MQTT');
      const payload = action.payload;
      mqttRedux = createClient(payload.config);
      //Add actions mapped to topics here
      const actionTopicMapping = {
        SUBSCRIBE_TO_RSP_DATA: payload.topics.rsp,
        SUBSCRIBE_TO_CAG: payload.topics.cag
      };

      mqttRedux.connect(actionTopicMapping, store);
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Connecting to MQTT broker'
      };

    case 'MQTT_PUBLISH':
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'MQTT Message Published to Topic'
      };

    case 'PUBLISH_TO_MQTT':
      const publish_payload = action.payload;
      mqttRedux.publish(publish_payload, store);
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Published MQTT Message to Topic'
      };
    case SUBSCRIBE_TO_TOPIC:
      const topic_payload = action.payload;
      mqttRedux.subscribe_to_topic(topic_payload, store);
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Topic successfully subscribe'
      };
    case 'SUBSCRIBE_TO_RSP_DATA':
      let rsp_payload = JSON.parse(action.payload);

      return {
        ...state,
        rspData: {
          payload: rsp_payload,
          topic: action.topic
        }
      };

    case 'MQTT_CONNECT':
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Connected to MQTT broker'
      };

    case 'MQTT_DISCONNECT':
      console.log('DISCONNECTED FROM MQTT BROKER');
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: 'Disconnected from MQTT broker'
      };

    case 'MQTT_ERROR':
      return {
        ...state,
        showNotification: true,
        newNotificationMessage: action.err.message
      };

    case 'SNACKBAR_CLOSE':
      return {
        ...state,
        showNotification: false,
        newNotificationMessage: ''
      };
    default:
      return state;
  }
};

export default rootReducer;
