/*
The search feature will likely be a bit wonky. I hide columns from view rather than removing them.
This results in their hidden content also being search on.

The reason I used this approach rather than removing the columns was a bug in primereact's code.
This bug would prevent columns from being added after a reorder operation was executed.
*/

import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import store from '../../../store/index';
import { saveCagInventory, setDrawerMenuItem } from '../../../actions/index';
import PagableTable from '../../../components/PagableTable';
import { IState } from '../../../reducers';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: theme.spacing(3)
  },

  [`& .${classes.table}`]: {
    minWidth: 1020
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block'
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none'
  },

  [`&.${classes.divBg}`]: {
    padding: 25
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35
  }
}));

const mapStateToProps = (state: IState) => {
  return {
    cagInventory: state.cagInventory
  };
};

class EnhancedTable extends React.Component<any, any> {
  state = {
    cols: [],
    loading: true,
    anchorEl: null
  };

  async componentDidMount() {
    store.dispatch(setDrawerMenuItem('gateways'));
    try {
      let sessionDetails = await Auth.currentCredentials();
      const profile = await Auth.currentAuthenticatedUser();
      this.setState({
        IdentityId: sessionDetails.data.IdentityId,
        userEmail: profile.username
      });
    } catch {
      alert('inventory gateway error');
    }
  }

  render() {
    const { cagInventory } = this.props;

    const selectedColumns = ['Barcode', 'Radio Fw', 'Part Number', 'IP Address', 'App Version', 'Network ID', 'Site', 'Stage'];
    const columnHeaders = [
      { field: 'barcode', header: 'Barcode' },
      { field: 'xbeeRadioFw', header: 'Radio Fw' },
      { field: 'partNumber', header: 'Part Number' },
      { field: 'revision', header: 'Revision' },
      { field: 'ipAddress', header: 'IP Address' },
      { field: 'swVersion', header: 'App Version' },
      { field: 'xbeeNetworkId', header: 'Network ID' },
      { field: 'xbeeMacAddr', header: 'Xbee MAC' },
      { field: 'bbMacAddr', header: 'BB MAC' },
      { field: 'bbSerialNo', header: 'BB Serial No.' },
      { field: 'bbVersion', header: 'BB Version' },
      { field: 'iccid', header: 'ICCID' },
      { field: 'imei', header: 'IMEI' },
      { field: 'ubootVersion', header: 'UBoot Version' },
      { field: 'siteName', header: 'Site' },
      { field: 'stage', header: 'Stage' }
    ];

    return (
      <Root className={classes.divBg}>
        <PagableTable
          endpoint='logging/cags'
          tableName='Gateway Inventory'
          columnsSelected={selectedColumns}
          columnHeaders={columnHeaders}
          defaultData={cagInventory}
          saveAction={saveCagInventory}
          links={{
            barcode: {
              destination: 'gateways'
            },
            siteName: {
              destination: 'sites',
              translation: 'siteId'
            }
          }}
        />
      </Root>
    );
  }
}

const List = connect(mapStateToProps)(EnhancedTable);
export default List;
