export const MANUFACTURING_TEST_WSN = 'manufacturing/devices/wsn';
export const MANUFACTURING_TEST_CAG = 'manufacturing/devices/cag';
export const MANUFACTURING_TEST_HMC = 'manufacturing/devices/hmc';
export const MANUFACTURING_BATCH_UPDATER = 'manufacturing/devices/batchUpdater';
export const HMC_INVENTORY = 'devices/list/hmc';
export const CAG_INVENTORY = 'devices/list/cag';
export const DEVICE_INVENTORY = 'devices/list/device';
export const GET_USER_PERMISSIONS = 'users/permissions';
export const ERROR_HISTORY = 'logging/errors';
export const ALERT_HISTORY = 'logging/alerts';
export const SITE_CONFIG_HISTORY = 'logging/siteconfig';
export const THROW_ERROR = 'logging/errors/throw';
export const GENERATE_ERROR_REPORT = 'logging/errors/report';
export const GET_LOG = 'logging/errors/getevents';
export const GET_KPI = 'logging/kpi';
export const SSH_COMMAND = 'devices/ssh/command';
