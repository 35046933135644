import React from 'react';
import DashboardLink from './PrivateLinks/dashboard';
import InventoryLinks from './PrivateLinks/inventory';

export const privateListItems = (childProps: any) => {
  return (
    <div>
      <DashboardLink props={childProps} />
      <InventoryLinks props={childProps} />
    </div>
  );
};
