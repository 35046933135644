/*
The search feature will likely be a bit wonky. I hide columns from view rather than removing them.
This results in their hidden content also being search on.

The reason I used this approach rather than removing the columns was a bug in primereact's code.
This bug would prevent columns from being added after a reorder operation was executed.
*/

import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { Auth } from 'aws-amplify';
import { POST_ENDPOINT, GET_ENDPOINT } from '../api-request/';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import store from './../store/index';

const PREFIX = 'PagableTable';

const classes = {
  root: `${PREFIX}-root`,
  paperTextRoot: `${PREFIX}-paperTextRoot`,
  table: `${PREFIX}-table`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tableWrapperNon: `${PREFIX}-tableWrapperNon`,
  divBg: `${PREFIX}-divBg`,
  loading: `${PREFIX}-loading`,
  textField: `${PREFIX}-textField`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%'
  },

  [`& .${classes.paperTextRoot}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    height: 510
  },

  [`& .${classes.table}`]: {
    minWidth: 1020
  },

  [`& .${classes.tableWrapper}`]: {
    overflowX: 'auto',
    display: 'block'
  },

  [`& .${classes.tableWrapperNon}`]: {
    display: 'none'
  },

  [`& .${classes.divBg}`]: {
    padding: 25
  },

  [`& .${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 35
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface IProps {
  id: string;
  tableName: string;
  endpoint: string;
  columnStyles: any[];
  columnHeaders: any[];
  columnsSelected: any[];
  queryAgainstSource: any;
  onChange: any;
  log: boolean;
  defaultData: any;
  saveAction: any;
  links: any;
  templates: any;
  columnFilters: any[];
}
class EnhancedTable extends React.Component<IProps, any> {
  static defaultProps: Partial<IProps> = {
    links: {},
    templates: {},
    columnFilters: []
  };
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      id: '',
      anchorEl: null,
      hashSalt: '',
      startIndex: 0,
      from: 0,
      columnHeaders: [],
      columnsSelected: [],
      filters: {},
      options: {},
      fieldTranslation: {},
      rowsPerPage: 10,
      filterFields: []
    };
  }

  componentDidMount = async () => {
    const { endpoint, id, columnsSelected, columnHeaders, defaultData, saveAction, columnFilters } = this.props;

    let filteredOutColumns = [];

    if (defaultData && Object.keys(defaultData).length > 0) {
      const options = defaultData.options;
      let fieldTranslation = defaultData.translations;
      if (typeof fieldTranslation === 'undefined' || Object.keys(fieldTranslation).length < columnHeaders.length) {
        if (typeof fieldTranslation === 'undefined') {
          fieldTranslation = {};
        }
        for (let header of columnHeaders) {
          if (typeof fieldTranslation[header.field] === 'undefined') {
            fieldTranslation[header.field] = header.field;
          }
        }
      }
      this.setState({
        rows: defaultData.docs,
        columnsSelected: columnsSelected,
        columnHeaders: columnHeaders,
        loading: false,
        total: defaultData.total,
        options: options,
        fieldTranslation: fieldTranslation
      });
    } else {
      if (columnFilters.length > 0) {
        for (let column of columnFilters) {
          if (columnsSelected.indexOf(column) === -1) {
            // need to get field name for column header
            for (let columnHeader of columnHeaders) {
              if (columnHeader.header === column) {
                filteredOutColumns.push(columnHeader.field);
                break;
              }
            }
          }
        }
      }

      this.setState({
        loading: true,
        columnsSelected: columnsSelected,
        columnHeaders: columnHeaders,
        filterFields: filteredOutColumns
      });
    }

    try {
      //Get List
      let response;
      if (id) {
        response = await POST_ENDPOINT(endpoint, {
          id: id,
          filterFields: filteredOutColumns
        });
      } else {
        if (filteredOutColumns.length > 0) {
          response = await POST_ENDPOINT(endpoint, {
            filterFields: filteredOutColumns
          });
        } else {
          response = await GET_ENDPOINT(endpoint);
        }
      }

      if (typeof saveAction === 'function') {
        store.dispatch(saveAction(response));
      }

      let rows = response.docs;

      const options = response.options ? response.options : {};
      let fieldTranslation = response.translations;
      if (typeof fieldTranslation === 'undefined' || Object.keys(fieldTranslation).length < columnHeaders.length) {
        if (typeof fieldTranslation === 'undefined') {
          fieldTranslation = {};
        }
        for (let header of columnHeaders) {
          if (typeof fieldTranslation[header.field] === 'undefined') {
            fieldTranslation[header.field] = header.field;
          }
        }
      }

      //Update state
      this.setState({
        rows: rows,
        loading: false,
        total: response.total,
        options: options,
        fieldTranslation: fieldTranslation
      });
    } catch {
      alert('Error');
    }
    try {
      let sessionDetails = await Auth.currentCredentials();
      const profile = await Auth.currentAuthenticatedUser();
      this.setState({
        IdentityId: sessionDetails.data.IdentityId,
        userEmail: profile.username,
        sessionDetails: sessionDetails
      });
    } catch {
      alert('Error');
    }
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleRefresh = async () => {
    const { endpoint, id } = this.props;
    const { filters, sort, rowsPerPage, filterFields } = this.state;
    console.log('Refresh');
    try {
      //Get List of Devices
      let response;
      if (id) {
        response = await POST_ENDPOINT(endpoint, {
          id: id,
          filters: filters,
          sort: sort,
          size: rowsPerPage,
          filterFields: filterFields
        });
      } else if (filters) {
        response = await POST_ENDPOINT(endpoint, {
          filters: filters,
          sort: sort,
          size: rowsPerPage,
          filterFields: filterFields
        });
      } else {
        response = await GET_ENDPOINT(endpoint);
      }
      let rows = response.docs;

      const options = response.options ? response.options : {};

      //Update state
      this.setState({
        rows: rows,
        loading: false,
        total: response.total,
        options: options
      });
    } catch {
      alert('Error');
    }
  };

  onPage = async (event: any) => {
    const { endpoint, id } = this.props;
    const { filters, sort, filterFields } = this.state;
    this.setState({
      loading: true
    });

    const nextPage = event.first;
    const rowsPerPage = event.rows;
    try {
      let response;
      if (id) {
        response = await POST_ENDPOINT(endpoint, {
          id: id,
          from: nextPage,
          filters: filters,
          sort: sort,
          size: rowsPerPage,
          filterFields: filterFields
        });
      } else {
        response = await POST_ENDPOINT(endpoint, {
          from: nextPage,
          filters: filters,
          sort: sort,
          size: rowsPerPage,
          filterFields: filterFields
        });
      }

      //Update state
      this.setState({
        rows: response.docs,
        loading: false,
        total: response.total,
        startIndex: event.first,
        from: nextPage,
        rowsPerPage
      });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  };

  onSort = async (event: any) => {
    console.log('On sort!');
    const { id, endpoint } = this.props;
    const { filters, nextPage, fieldTranslation, rowsPerPage, filterFields } = this.state;
    const sort = {
      field: fieldTranslation[event.sortField],
      order: event.sortOrder === -1 ? 'desc' : 'asc'
    };

    this.setState({
      sortField: event.sortField,
      sortOrder: event.sortOrder,
      loading: true,
      sort: sort
    });

    let response = await POST_ENDPOINT(endpoint, {
      id: id,
      from: nextPage,
      filters: filters,
      sort: sort,
      size: rowsPerPage,
      filterFields: filterFields
    });

    //Update state
    this.setState({
      rows: response.docs,
      loading: false,
      total: response.total
    });
  };

  handleClose = () => {
    console.log('Close');
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (index: any) => {
    const { columnFilters } = this.props;
    let { columnsSelected, columnHeaders, filterFields, handling } = this.state;
    if (handling) {
      return;
    } else {
      this.setState({
        handling: true
      });
    }
    let clickedColumn = columnHeaders[index];
    let headerIndex = columnsSelected.indexOf(clickedColumn.header);
    let filterHeaderIndex = filterFields.indexOf(clickedColumn.field);
    // let cols = []

    if (headerIndex > -1) {
      if (columnFilters.indexOf(clickedColumn.header) !== -1) {
        filterFields.push(clickedColumn.field);
        this.requery();
      }
      columnsSelected.splice(headerIndex, 1);
    } else {
      if (columnFilters.indexOf(clickedColumn.header) !== -1) {
        filterFields.splice(filterHeaderIndex, 1);
        this.requery();
      }
      columnsSelected.push(clickedColumn.header);
    }

    this.setState({
      selectedIndex: index,
      columnsSelected: columnsSelected,
      filterFields: filterFields,
      handling: false
    });
  };

  selectionChanged = async (e: any) => {
    const { onChange } = this.props;

    console.log('Selection changed!');

    if (typeof onChange === 'function') {
      onChange();
    }

    if (this.state.selectedRow !== e.value) {
      this.setState({
        selectedRow: e.value
      });
    }
  };

  timestampTemplate(rowData: any[], column: any) {
    const data = rowData[column.field] ? rowData[column.field] : '';
    let date = new Date(data.replace('+0000', '+00:00'));
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return <span>{months[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear() + ' @ ' + hour + ':' + minute}</span>;
  }

  onFilter = async (event: any, field: any) => {
    console.log('Filter');
    const { id, endpoint } = this.props;
    let { filters, nextPage, sort, rowsPerPage, filterFields } = this.state;
    if (event.value) {
      if (filters[field] && (event.value.length > 0 ? event.value : undefined) === filters[field]) {
        return;
      } else {
        filters[field] = event.value.length > 0 ? event.value : undefined; // Prevents an empty list from returning no results
      }
    } else if (filters[field] && event.target.value === filters[field]) {
      return;
    } else {
      filters[field] = event.target.value;
    }

    let update = true;

    if (field !== 'uptime' && field.toLowerCase().includes('time')) {
      update = filters[field][0] != null && filters[field][1] != null;
    }

    if (update) {
      this.setState({
        filters: filters,
        loading: true
      });

      let response = await POST_ENDPOINT(endpoint, {
        id: id,
        from: nextPage,
        filters: filters,
        sort: sort,
        size: rowsPerPage,
        filterFields: filterFields
      });

      this.setState({
        rows: response.docs,
        total: response.total,
        loading: false
      });
    } else {
      this.setState({
        filters: filters
      });
    }
  };

  requery = async () => {
    const { id, endpoint } = this.props;
    let { filters, nextPage, sort, rowsPerPage, filterFields } = this.state;
    this.setState({
      loading: true
    });

    let response = await POST_ENDPOINT(endpoint, {
      id: id,
      from: nextPage,
      filters: filters,
      sort: sort,
      size: rowsPerPage,
      filterFields: filterFields
    });

    this.setState({
      rows: response.docs,
      total: response.total,
      loading: false
    });
  };

  handleKeyPress = (event: any, fieldName: string) => {
    if (event.charCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.onFilter(event, fieldName);
    }
  };

  columnFilter = (field: any) => {
    const { options, filters, fieldTranslation } = this.state;

    if (field) {
      let fieldName = fieldTranslation[field];
      if (options && options[field]) {
        return (
          <MultiSelect
            style={{ width: '100%' }}
            value={filters[fieldName]}
            options={options[field]}
            onChange={(event) => this.onFilter(event, fieldName)}
            appendTo={document.body}
          />
        );
      } else if (field !== 'uptime' && (field.toLowerCase().includes('time') || field.toLowerCase() === 'archived')) {
        return (
          <Calendar
            style={{ width: '100%' }}
            selectionMode='range'
            value={filters[fieldName]}
            onChange={(event) => this.onFilter(event, fieldName)}
            appendTo={document.body}
            readOnlyInput={false}
          />
        );
      } else {
        return (
          <InputText
            style={{ width: '100%' }}
            onBlur={(event) => this.onFilter(event, fieldName)}
            onKeyPress={(event) => this.handleKeyPress(event, fieldName)}
          />
        );
      }
    }

    return undefined;
  };

  linkTemplate = (rowData: any[], column: any) => {
    const { links } = this.props;
    let dataField = links[column.field].translation ? links[column.field].translation : column.field;
    let uriData = rowData[dataField];
    if (rowData[column.field] && typeof uriData === 'string') {
      uriData = encodeURIComponent(uriData);
    }
    return (
      <Link
        to={{
          pathname: `/${links[column.field].destination}/${uriData}`,
          state: rowData
        }}
      >
        {rowData[column.field]}
      </Link>
    );
  };

  columnRenderer = (field: any) => {
    const { links, templates } = this.props;
    if (Object.keys(templates).includes(field)) {
      return templates[field];
    } else if (field !== 'uptime' && field.toLowerCase().includes('time')) {
      return this.timestampTemplate;
    } else if (Object.keys(links).includes(field)) {
      return this.linkTemplate;
    } else {
      return undefined;
    }
  };

  render() {
    const { total, startIndex, rows, loading, selectedRow, anchorEl, columnsSelected, columnHeaders, sortField, sortOrder, rowsPerPage } = this.state;
    const { tableName, columnStyles } = this.props;

    let columns = columnStyles;
    if (typeof columns === 'undefined') {
      columns = columnHeaders
        .filter((col: any) => {
          return columnsSelected.includes(col.header);
        })
        .map((col: any) => {
          return (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable={true}
              body={this.columnRenderer(col.field)}
              filter={typeof col.filter === 'undefined' ? true : col.filter}
              filterElement={this.columnFilter(col.field)}
            />
          );
        });
    }

    const header = (
      <Grid container>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Root style={{ textAlign: 'right', verticalAlign: 'center' }}>
            <IconButton aria-label='Refresh' aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup='true' onClick={this.handleRefresh} size='large'>
              <RefreshIcon />
            </IconButton>
            <IconButton aria-label='More' aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup='true' onClick={this.handleClick} size='large'>
              <MoreVertIcon />
            </IconButton>

            <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
              {columnHeaders.map((col: any, index: number) => (
                <MenuItem key={col.field} value={col.header} onClick={() => this.handleMenuItemClick(index)}>
                  <FormControlLabel
                    control={<Checkbox checked={columnsSelected.indexOf(col.header) > -1} onClick={(event) => event.preventDefault()} />}
                    label={col.header}
                    onClick={(event) => event.preventDefault()}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Root>
        </Grid>
      </Grid>
    );

    return (
      <div>
        <h3>{tableName}</h3>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Paper elevation={2} className={classes.root}>
              <div className='content-section implementation'>
                <DataTable
                  value={rows}
                  reorderableColumns={true}
                  header={header}
                  paginator={true}
                  onPage={this.onPage}
                  rows={rowsPerPage}
                  rowsPerPageOptions={[10, 20, 50]}
                  totalRecords={total}
                  lazy={true}
                  onSort={this.onSort}
                  resizableColumns={true}
                  columnResizeMode='fit'
                  loading={loading}
                  selectionMode='single'
                  selection={selectedRow}
                  onSelectionChange={this.selectionChanged}
                  first={startIndex}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  emptyMessage='No data in selection'
                >
                  {columns}
                </DataTable>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default EnhancedTable;
