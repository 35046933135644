import React from 'react';
import Typography from '@mui/material/Typography';

function TabContainer(props: any) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

export default TabContainer;
