export const NEW_NOTIFICATION_MESSAGE = "NEW_NOTIFICATION_MESSAGE";
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const CONNECT_TO_MQTT = "CONNECT_TO_MQTT";
export const SUBSCRIBE_TO_DEFAULT_TOPIC = "SUBSCRIBE_TO_DEFAULT_TOPIC";
export const PUBLISH_TO_MQTT = "PUBLISH_TO_MQTT";
export const SUBSCRIBE_TO_TOPIC = "SUBSCRIBE_TO_TOPIC";
export const SAVE_CAG_INVENTORY = "SAVE_CAG_INVENTORY";
export const SAVE_DEVICE_INVENTORY = "SAVE_DEVICE_INVENTORY";
export const SAVE_SITE_LIST = "SAVE_SITE_LIST";
export const SAVE_ERROR_LIST = "SAVE_ERROR_LIST";
export const SAVE_KPI = "SAVE_KPI";
export const SET_DRAWER_MENU_ITEM = "SET_DRAWER_MENU_ITEM";
