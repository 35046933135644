import React from 'react';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';

const PREFIX = 'LoaderButton';

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonSuccess: `${PREFIX}-buttonSuccess`,
  fabProgress: `${PREFIX}-fabProgress`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.wrapper}`]: {
    position: 'relative'
  },

  [`& .${classes.buttonSuccess}`]: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  },

  [`& .${classes.fabProgress}`]: {
    color: blue[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },

  [`& .${classes.buttonProgress}`]: {
    color: blue[900],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

class CircularIntegration extends React.Component<any, any> {
  render() {
    const buttonClassname = classNames({
      [classes.buttonSuccess]: false
    });

    return (
      <Root className={classes.root}>
        <div className={classes.wrapper}>
          <Button variant='contained' color='primary' className={buttonClassname} disabled={this.props.disabled || this.props.isLoading} type='submit'>
            {!this.props.isLoading ? this.props.text : this.props.loadingText}
          </Button>
          {this.props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </Root>
    );
  }
}

export default CircularIntegration;
