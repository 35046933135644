import React from "react";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const PREFIX = 'ItemGrid';

const classes = {
  grid: `${PREFIX}-grid`
};

const StyledGrid = styled(Grid)({
  [`&.${classes.grid}`]: {
    padding: "0 15px !important"
  }
});

function ItemGrid({ ...props }) {
  const {  children, ...rest } = props;
  return (
    <StyledGrid item {...rest} className={classes.grid}>
      {children}
    </StyledGrid>
  );
}

export default (ItemGrid);
