import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chartist from 'chartist';
import React, { Component } from 'react';
import { DEV_COLOR, PROD_COLOR, TEST_COLOR } from '../assets/jss/material-dashboard-react';

const PREFIX = 'BarCard';

const classes = {
  graphStyle: `${PREFIX}-graphStyle`
};

const Root = styled('div')(() => ({
  [`& .${classes.graphStyle}`]: {
    '& .ct-line': { strokeWidth: '2px' },
    '& .ct-grids line': { stroke: 'grey' },
    '& .ct-labels span': { color: 'grey' },
    '& .ct-series-a .ct-line': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-line': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-line': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-bar': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-bar': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-bar': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-slice-donut': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-slice-donut': {
      stroke: '#efefef',
      opacity: 10.0
    },
    height: '100%'
  }
}));

interface IProps {
  title: string;
  text: string;
  statIcon: any;
  statIconColor: string;
  chartColor: string;
  statLink: any;
  statText: string;
  value: {
    dev: {
      Timestamps: any[];
      Values: any[];
    };
    test: { Timestamps: any[]; Values: any[] };
    prod: { Timestamps: any[]; Values: any[] };
  };
  prefix: string;
  suffix: string;
  scaleFunction: any;
  id: string;
  enabledKeys: any[];
}

class BarCard extends Component<IProps, any> {
  static defaultProps: Partial<IProps> = {
    statIconColor: 'gray',
    chartColor: 'blue',
    value: {
      dev: { Timestamps: [], Values: [] },
      test: { Timestamps: [], Values: [] },
      prod: { Timestamps: [], Values: [] }
    },
    prefix: '',
    suffix: '',
    enabledKeys: ['dev', 'test', 'prod']
  };
  render() {
    const { title, value, prefix, suffix, id, enabledKeys, scaleFunction } = this.props;

    var data: any = {
      labels: [],
      series: []
    };
    let total = 0;
    let number = 0;
    let key: 'dev' | 'test' | 'prod';
    for (key in value) {
      // console.log("KEY", key, value[key])
      data.labels = value[key].Timestamps;
      if (enabledKeys.indexOf(key) > -1) {
        data.series.push({
          name: key,
          data: value[key].Values
        });
        if (value[key].Values) {
          for (let datapoint of value[key].Values) {
            total += datapoint;
            number += 1;
          }
        }
      } else {
        data.series.push({
          name: key,
          data: [0, 0, 0, 0, 0, 0, 0]
        });
      }
    }

    let exponent = Math.round(Math.log10(total / number));

    var options = {
      stackBars: true,
      axisX: {
        labelInterpolationFnc: function (value: string) {
          let temp = value.split('-');
          return temp[1] + '/' + temp[2];
        }
      },
      axisY: {
        labelInterpolationFnc: function (value: number) {
          if (typeof scaleFunction === 'function') {
            return prefix + scaleFunction(value, exponent);
          } else {
            return prefix + value.toFixed(0) + suffix;
          }
        }
      }
    };

    new Chartist.Bar('#' + id, data, options);

    return (
      <Root style={{ textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <div id={id} className={classes.graphStyle} />
      </Root>
    );
  }
}

export default BarCard;
// export default BarCard;
