import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chartist from 'chartist';
import { DEV_COLOR, TEST_COLOR, PROD_COLOR } from '../assets/jss/material-dashboard-react';

const PREFIX = 'ChartCard';

const classes = {
  graphStyle: `${PREFIX}-graphStyle`
};

const Root = styled('div')(() => ({
  [`& .${classes.graphStyle}`]: {
    '& .ct-line': { strokeWidth: '2px' },
    '& .ct-grids line': { stroke: 'grey' },
    '& .ct-labels span': { color: 'grey' },
    '& .ct-series-a .ct-line': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-line': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-line': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-bar': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-bar': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-bar': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-slice-donut': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-slice-donut': {
      stroke: '#efefef',
      opacity: 10.0
    },
    height: '100%'
  }
}));

interface IProps {
  title: string;
  text: string;
  statIcon: any;
  statIconColor: 'warning' | 'primary' | 'danger' | 'success' | 'info' | 'rose' | 'gray';
  chartColor: 'orange' | 'green' | 'red' | 'blue' | 'purple';
  statLink: any;
  statText: string;
  value: {
    dev: {
      Timestamps: any[];
      Values: any[];
    };
    test: { Timestamps: any[]; Values: any[] };
    prod: { Timestamps: any[]; Values: any[] };
  };
  prefix: string;
  suffix: string;
  id: string;
  enabledKeys: any[];
  scale: number;
}

class ChartCard extends Component<IProps, any> {
  static defaultProps: Partial<IProps> = {
    statIconColor: 'gray',
    chartColor: 'blue',
    value: {
      dev: { Timestamps: [], Values: [] },
      test: { Timestamps: [], Values: [] },
      prod: { Timestamps: [], Values: [] }
    },
    prefix: '',
    suffix: '',
    enabledKeys: ['dev', 'test', 'prod'],
    scale: 1
  };

  render() {
    const { title, value, prefix, suffix, id, enabledKeys, scale } = this.props;

    var data: any = {
      labels: [],
      series: []
    };
    let key: 'dev' | 'test' | 'prod';
    for (key in value) {
      data.labels = value[key].Timestamps;
      data.series.push({
        name: key,
        data: enabledKeys.indexOf(key) > -1 ? value[key].Values : []
      });
    }
    var options = {
      showPoint: false,
      axisX: {
        labelInterpolationFnc: function (value: any) {
          let timestamp = new Date(value);
          if (timestamp.getHours() === 0 && timestamp.getMinutes() === 0) {
            let date = timestamp.getDate() > 9 ? '' + timestamp.getDate() : '0' + timestamp.getDate();
            let month = timestamp.getMonth() + 1 > 9 ? '' + (timestamp.getMonth() + 1) : '0' + (timestamp.getMonth() + 1);
            return month + '/' + date;
          } else {
            return null;
          }
        }
      },
      axisY: {
        labelInterpolationFnc: function (value: any) {
          return prefix + Math.round(value / scale) + suffix;
        }
      }
    };

    new Chartist.Line('#' + id, data, options);

    return (
      <Root style={{ textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <div id={id} className={classes.graphStyle} />
      </Root>
    );
  }
}

export default ChartCard;
