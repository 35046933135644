import React from 'react';
import { styled } from '@mui/material/styles';
import store from '../../../store/index';
import { setDrawerMenuItem } from '../../../actions/index';
import PagableTable from '../../../components/PagableTable';
const PREFIX = 'device-details';

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paper: `${PREFIX}-paper`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative'
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },

  [`& .${classes.paper}`]: {
    maxWidth: 600,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

class DeviceDetails extends React.Component<any, { loading: boolean; count: number; deviceStateArray: any[]; identity: any; barcode: string }> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      count: 0,
      deviceStateArray: [],
      identity: {},
      barcode: ''
    };
  }

  async componentDidMount() {
    const barcode = window.location.pathname.split('/')[2];
    store.dispatch(setDrawerMenuItem('devices'));
    this.setState({ barcode, loading: false });
  }

  render() {
    const { loading } = this.state;

    const inventorySelectedColumns = ['Time Updated', 'App Fw', 'Radio Fw', 'Gateway', 'Comment'];
    const inventoryColumnHeaders = [
      { field: 'timeCreated', header: 'Time Updated' },
      { field: 'xbeeAppFw', header: 'App Fw' },
      { field: 'xbeeRadioFw', header: 'Radio Fw' },
      { field: 'xbeeMacAddr', header: 'MAC' },
      { field: 'partNumber', header: 'Part Number' },
      { field: 'revision', header: 'Revision' },
      { field: 'cagBarcode', header: 'Gateway' },
      { field: 'comment', header: 'Comment' },
      { field: 'stage', header: 'Stage' }
    ];

    if (loading) {
      return <Root className={classes.root} />;
    } else {
      return (
        <div className='divBg'>
          <h3>
            Device Details: <strong>{this.props.match.params.barcode}</strong>{' '}
          </h3>
          <PagableTable
            endpoint={'logging/devices/' + this.props.match.params.barcode}
            tableName='Inventory History'
            columnsSelected={inventorySelectedColumns}
            columnHeaders={inventoryColumnHeaders}
            links={{ cagBarcode: 'gateways' }}
          />
        </div>
      );
    }
  }
}

export default DeviceDetails;
