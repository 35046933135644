import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
// import ReactJson from 'react-json-view'
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { connect } from 'react-redux';
import { newNotificationMessage, openNotification } from '../../actions/';
import { POST_ENDPOINT, GET_ENDPOINT, PUT_ENDPOINT, DELETE_ENDPOINT } from '../../api-request/';
import TabContainer from '../../components/TabContainer';

const PREFIX = 'index';

const classes = {
  divBg: `${PREFIX}-divBg`,
  container: `${PREFIX}-container`,
  root: `${PREFIX}-root`,
  padding: `${PREFIX}-padding`,
  tableRoot: `${PREFIX}-tableRoot`,
  table: `${PREFIX}-table`,
  button: `${PREFIX}-button`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.divBg}`]: {
    padding: 25
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 15
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
    backgroundColor: '#eeeeee'
  },

  [`& .${classes.padding}`]: {
    padding: `0 ${theme.spacing(2)}`
  },

  [`& .${classes.tableRoot}`]: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },

  [`& .${classes.table}`]: {
    minWidth: 700
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1)
  }
}));

const mapDispatchToProps = (dispatch: any) => {
  return {
    openNotification: (flag: any) => dispatch(openNotification(flag)),
    newNotificationMessage: (message: any) => dispatch(newNotificationMessage(message))
  };
};

class SimpleTabs extends React.Component<
  any,
  {
    value: any;
    sensorTypeData: {
      sensorType: any;
      type: any;
      unit: any;
      icon: any;
    };
    sensorTypeEditData: {
      sensorType: any;
      type: any;
      unit: any;
      icon: any;
    };
    userName: string;
    userEmail: string;
    sensorTypeEditDataId: string;
    sensorTypesCount: number;
    sensorTypesArray: any[];
    openDialogFlag: boolean;
  }
> {
  state = {
    value: 0,
    sensorTypeData: {
      sensorType: null,
      type: null,
      unit: null,
      icon: null
    },
    sensorTypeEditData: {
      sensorType: null,
      type: null,
      unit: null,
      icon: null
    },
    userName: '',
    sensorTypeEditDataId: '',
    sensorTypesCount: 0,
    sensorTypesArray: [],
    userEmail: '',
    openDialogFlag: false
  };

  handleCloseDialog = () => {
    this.setState({ openDialogFlag: false });
  };

  handleChange = (value: any) => {
    this.setState({ value });
  };

  handleLoadSensorTypes = async () => {
    try {
      const sensorTypes = await GET_ENDPOINT('sensortype/sensortype');
      const sampleSensorType = {
        sensorType: 'Ambient Temperature',
        type: 'T',
        unit: '&deg;C',
        icon: 'favicon'
      };
      this.setState({
        sensorTypeData: sampleSensorType,
        sensorTypesCount: sensorTypes.sensorTypes.length,
        sensorTypesArray: sensorTypes.sensorTypes
      });
    } catch (e) {
      alert(e);
      //this.setState({ isLoading: false });
    }
  };

  handleAddNewSensorType = async () => {
    var sensorTypePayloadSensorType = this.state.sensorTypeData.sensorType;
    var sensorTypePayloadtype = this.state.sensorTypeData.type;
    var sensorTypePayloadUnit = this.state.sensorTypeData.unit;
    var sensorTypePayloadIcon = this.state.sensorTypeData.icon;
    var sensorTypePayloadEditedBy = this.state.userEmail;
    const sampleSensorType = {
      editedBy: sensorTypePayloadEditedBy,
      sensorType: sensorTypePayloadSensorType,
      type: sensorTypePayloadtype,
      unit: sensorTypePayloadUnit,
      icon: sensorTypePayloadIcon
    };

    try {
      await POST_ENDPOINT('sensortype/sensortype', sampleSensorType);
      this.handleLoadSensorTypes();
      this.props.newNotificationMessage(this.state.sensorTypeData.sensorType + ' Sensor Type successfully created!');
      this.props.openNotification(true);
    } catch (e) {
      alert(e);
      //this.setState({ isLoading: false });
    }
  };

  handleDeleteSensorType = async (obj: any, e: any) => {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to delete this ' + obj.name + ' sensor type?');

    if (!confirmed) {
      return;
    }

    //this.setState({ isDeleting: true });

    try {
      await DELETE_ENDPOINT('sensortype/sensortype', obj.id);
      this.handleLoadSensorTypes();

      this.props.newNotificationMessage(obj.name + ' Sensor Type successfully deleted!');
      this.props.openNotification(true);
      //this.props.history.push("/");
    } catch (e) {
      alert(e);
      //this.setState({ isDeleting: false });
    }
  };

  handleEditSensorType = async (obj: any, e: any) => {
    e.preventDefault();
    const sampleSensorType = {
      sensorType: obj.name,
      type: obj.type,
      unit: obj.unit,
      icon: obj.icon
    };
    this.setState({
      openDialogFlag: true,
      sensorTypeEditData: sampleSensorType,
      sensorTypeEditDataId: obj.id
    });
  };

  handleCloseDialogAndSave = async () => {
    var sensorTypePayloadSensorType = this.state.sensorTypeEditData.sensorType;
    var sensorTypePayloadType = this.state.sensorTypeEditData.type;
    var sensorTypePayloadUnit = this.state.sensorTypeEditData.unit;
    var sensorTypePayloadIcon = this.state.sensorTypeEditData.icon;
    var sensorTypePayloadEditedBy = this.state.userEmail;
    const sampleSensorType = {
      editedBy: sensorTypePayloadEditedBy,
      sensorType: sensorTypePayloadSensorType,
      type: sensorTypePayloadType,
      unit: sensorTypePayloadUnit,
      icon: sensorTypePayloadIcon
    };

    try {
      await PUT_ENDPOINT('sensortype/sensortype', this.state.sensorTypeEditDataId, sampleSensorType);
      this.handleLoadSensorTypes();
      this.setState({ openDialogFlag: false });

      this.props.newNotificationMessage(this.state.sensorTypeEditData.sensorType + ' Sensor Type successfully updated!');
      this.props.openNotification(true);
    } catch (e) {
      alert(e);
      //this.setState({ isLoading: false });
    }
  };

  onEditSensorData = (e: any) => {
    this.setState({ sensorTypeEditData: e.updated_src });
  };

  async componentDidMount() {
    try {
      const sensorTypes = await GET_ENDPOINT('sensortype/sensortype');
      const profile = await Auth.currentAuthenticatedUser();
      var unit = '&deg;C';
      const sampleSensorType = {
        sensorType: 'Ambient Temperature',
        type: 'T',
        unit: unit.toString(),
        icon: 'favicon'
      };
      this.setState({
        sensorTypeData: sampleSensorType,
        userEmail: profile.username,
        sensorTypesCount: sensorTypes.sensorTypes.length,
        sensorTypesArray: sensorTypes.sensorTypes
      });
    } catch (e) {
      alert(e);
      //this.setState({ isLoading: false });
    }
  }
  render() {
    const {} = this.props;
    const { value } = this.state;

    return (
      <Root className={classes.divBg}>
        <h3>Users</h3>
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.root}>
                <AppBar position='static'>
                  <Tabs value={value} onChange={this.handleChange}>
                    <Tab
                      label={
                        <Badge className={classes.padding} color='secondary' badgeContent={this.state.sensorTypesCount}>
                          Types
                        </Badge>
                      }
                    />
                    <Tab label='Add New Type' />
                  </Tabs>
                </AppBar>
                {value === 0 && (
                  <TabContainer>
                    <Typography variant='body2' gutterBottom>
                      Sensor Types
                    </Typography>
                    {this.state.sensorTypesArray.length === 0 ? (
                      'No sensor types avaialable'
                    ) : (
                      <Fragment>
                        <Paper elevation={2} className={classes.tableRoot}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Sensor Type</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Icon</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.sensorTypesArray.map((n: any, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{n.sensorType}</TableCell>
                                    <TableCell>{n.type}</TableCell>
                                    <TableCell>{n.unit}</TableCell>
                                    <TableCell>{n.icon}</TableCell>
                                    <TableCell>{moment(Number(n.updatedAt)).format('MM/DD/YYYY')}</TableCell>
                                    <TableCell>
                                      <Fab color='primary' aria-label='edit' className={classes.button} onClick={(e) => this.handleEditSensorType(n, e)}>
                                        <Icon>edit_icon</Icon>
                                      </Fab>
                                      <Fab color='primary' aria-label='delete' className={classes.button} onClick={(e) => this.handleDeleteSensorType(n, e)}>
                                        <DeleteIcon />
                                      </Fab>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Fragment>
                    )}
                  </TabContainer>
                )}
                {value === 1 && (
                  <Fragment>
                    <TabContainer>
                      <br />
                      <Divider />
                      <br />
                      <Button variant='contained' color='primary' onClick={this.handleAddNewSensorType}>
                        Add
                      </Button>
                    </TabContainer>
                  </Fragment>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <Dialog
          open={this.state.openDialogFlag}
          onClose={this.handleCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          fullWidth
        >
          <DialogTitle id='alert-dialog-title'>{'Edit Sensor Type'}</DialogTitle>
          <DialogContent>
            {/* <ReactJson
                src={this.state.sensorTypeEditData}
                collapsed={1}
                onEdit={this.onEditSensorData}
              /> */}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleCloseDialogAndSave} color='primary' autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Root>
    );
  }
}

const SimpleTabsInit = connect(null, mapDispatchToProps)(SimpleTabs);
export default SimpleTabsInit;
