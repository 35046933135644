import React from 'react';
import { Link } from 'react-router-dom';
import WithUserPermissions from '../../user-permissions-hoc/';
import SettingsRemote from '@mui/icons-material/SettingsRemoteOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CAN_VIEW_CONSOLE_DEVICES_LINK } from '../../constants/permission-types';
import { connect } from 'react-redux';
import store from '../../store/index';
import { setDrawerMenuItem } from '../../actions/index';
import { IState } from '../../reducers';

const mapStateToProps = (state: IState) => {
  return {
    drawerActiveMenu: state.drawerActiveMenu
  };
};

class DevicesLink extends React.Component<any, any> {
  handleListItemClick = (index: string) => {
    store.dispatch(setDrawerMenuItem(index));
  };

  render() {
    const { drawerActiveMenu } = this.props;

    return (
      <div>
        <span className='listTitle'>Inventory</span>
        <ListItem dense button component={Link} to='/devices' selected={drawerActiveMenu === 'devices'} onClick={() => this.handleListItemClick('devices')}>
          <ListItemIcon>
            <SettingsRemote className={drawerActiveMenu === 'devices' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText secondary='Devices' className={drawerActiveMenu === 'devices' ? 'activeLink' : 'nonactiveLink'} />
        </ListItem>
        <ListItem dense button component={Link} to='/gateways' selected={drawerActiveMenu === 'gateways'} onClick={() => this.handleListItemClick('gateways')}>
          <ListItemIcon>
            <SettingsRemote className={drawerActiveMenu === 'gateways' ? 'activeLink' : 'nonactiveLink'} />
          </ListItemIcon>
          <ListItemText secondary='Gateways' className={drawerActiveMenu === 'gateways' ? 'activeLink' : 'nonactiveLink'} />
        </ListItem>
      </div>
    );
  }
}

const List = connect(mapStateToProps)(DevicesLink);
export default WithUserPermissions(List, CAN_VIEW_CONSOLE_DEVICES_LINK);
