import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'StatsCard';

const classes = {
  root: `${PREFIX}-root`,
  cardTitle: `${PREFIX}-cardTitle`,
  cardTitleSmall: `${PREFIX}-cardTitleSmall`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(3)
  }
}));

interface IProps {
  icon: any;
  iconColor: 'orange' | 'green' | 'red' | 'blue' | 'purple';
  title: string;
  description: string;
  small: any;
  statIcon: any;
  statIconColor: 'warning' | 'primary' | 'danger' | 'success' | 'info' | 'rose' | 'gray';
  statLink: any;
  statText: string;
  value?: string;
}

class StatsCard extends Component<IProps> {
  static defaultProps: Partial<IProps> = {
    iconColor: 'purple',
    statIconColor: 'gray'
  };
  render() {
    const { title, small, value } = this.props;
    return (
      <Root style={{ textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <Typography variant='body2' className={classes.cardTitle}>
          {typeof value !== 'undefined' ? value : <CircularProgress size={28} />}{' '}
          {small !== undefined ? <small className={classes.cardTitleSmall}>{small}</small> : null}
        </Typography>
      </Root>
    );
  }
}

export default StatsCard;
