import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

const RounterComponent = ({ ...rest }: { [x: string]: any }) => {
  return (
    <Route
      {...rest}
      render={() => (
        <Fragment>
          <div className='NotFound'>
            <h3>Sorry, you do not have permissions set up for your account!</h3>
          </div>
        </Fragment>
      )}
    />
  );
};
export default RounterComponent;
