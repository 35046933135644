import React from 'react';
import { styled } from '@mui/material/styles';
// react plugin for creating charts
import { FileCopyOutlined, Store, InfoOutlined, DateRange, Accessibility } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { StatsCard, ItemGrid, ChartCard, BarCard, PieCard } from '../cards';
import { GET_ENDPOINT } from '../api-request/';
import { GET_KPI } from '../constants/end-points';
import { connect } from 'react-redux';
import store from './../store/index';
import { saveKPI, setDrawerMenuItem } from './../actions/index';
import { DEV_COLOR, TEST_COLOR, PROD_COLOR } from '../assets/jss/material-dashboard-react';
import { IState } from '../reducers';

const PREFIX = 'Home';

const classes = {
  statsRoot: `${PREFIX}-statsRoot`,
  graphStyle: `${PREFIX}-graphStyle`,
  divBg: `${PREFIX}-divBg`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.statsRoot}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

  [`& .${classes.graphStyle}`]: {
    '& .ct-line': { strokeWidth: '2px' },
    '& .ct-grids line': { stroke: 'grey' },
    '& .ct-labels span': { color: 'grey' },
    '& .ct-series-a .ct-line': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-line': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-line': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-bar': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-bar': {
      stroke: TEST_COLOR
    },
    '& .ct-series-c .ct-bar': {
      stroke: PROD_COLOR
    },
    '& .ct-series-a .ct-slice-donut': {
      stroke: DEV_COLOR
    },
    '& .ct-series-b .ct-slice-donut': {
      stroke: '#efefef',
      opacity: 10.0
    },
    height: '100%'
  },
  [`&.${classes.divBg}`]: {
    padding: 30,
    backgroundColor: 'transparent'
  }
}));

const mapStateToProps = (state: IState) => {
  return {
    kpi: state.kpi
  };
};

class Dashboard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      kpi: {},
      updatedAt: null,
      enabledKeys: ['dev', 'test', 'prod']
    };
  }
  interval: any;

  getMetrics = async () => {
    GET_ENDPOINT(GET_KPI).then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      for (let key in response) {
        kpi[key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/elastic').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['elastic'] = {};
      for (let key in response) {
        kpi['elastic'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/sms').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['smsCount'] = {};
      for (let key in response) {
        kpi['smsCount'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/byte').then((response) => {
      console.log('4', JSON.stringify(response));
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['byteCount'] = {};
      for (let key in response) {
        kpi['byteCount'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/cost').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['cost'] = {};
      for (let key in response) {
        kpi['cost'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/lambda').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['lambda'] = {};
      for (let key in response) {
        kpi['lambda'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/duration').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['duration'] = {};
      for (let key in response) {
        kpi['duration'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/gateway').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['gatewayCount'] = {};
      for (let key in response) {
        kpi['gatewayCount'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/device').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      kpi['deviceCount'] = {};
      for (let key in response) {
        kpi['deviceCount'][key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/missingerror').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      for (let key in response) {
        kpi[key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
    GET_ENDPOINT('logging/count/errors').then((response) => {
      let { kpi } = this.state;
      kpi.updatedAt = Date.now();
      for (let key in response) {
        kpi[key] = response[key];
      }
      store.dispatch(saveKPI(kpi));
      this.setState({ kpi });
    });
  };

  async componentDidMount() {
    // this.handleTableLoaded()
    store.dispatch(setDrawerMenuItem('home'));
    this.interval = setInterval(() => this.getMetrics(), 60000); //900000);
    let { kpi } = this.state;
    try {
      this.getMetrics();
      if (Object.keys(this.props.kpi).length !== 0) {
        kpi = this.props.kpi;
        this.setState({
          kpi: kpi
        });
      }
    } catch (e) {}
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  timeSince = (date: any) => {
    var seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    var interval = Math.floor(seconds / 31536000);

    if (interval > 0) {
      return interval + ' year' + (interval > 1 ? 's' : '  ago');
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 0) {
      return interval + ' month' + (interval > 1 ? 's' : '  ago');
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      return interval + ' day' + (interval > 1 ? 's' : '  ago');
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      return interval + ' hour' + (interval > 1 ? 's' : '  ago');
    }
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      return interval + ' minute' + (interval > 1 ? 's' : ' ago');
    }

    return 'Just updated';
  };

  formatSensorCount(count: number) {
    if (count) {
      if (count < 1e6) {
        return this.state.kpi.dataCount.toLocaleString();
      } else if (count < 1e9) {
        return (count / 1e6).toFixed(2) + 'M';
      } else {
        return (count / 1e9).toFixed(2) + 'B';
      }
    } else {
      return undefined;
    }
  }

  toggleStage = (stageName: any) => {
    let { enabledKeys } = this.state;
    const index = enabledKeys.indexOf(stageName);
    if (index < 0) {
      enabledKeys.push(stageName);
    } else {
      enabledKeys.splice(index, 1);
    }

    this.setState({ enabledKeys });
  };

  byteScaling = (value: any, exponent: any) => {
    let num;
    if (exponent >= 9) {
      num = value / 1e9;
      return num.toFixed(1) + 'GB';
    } else if (exponent >= 6) {
      num = value / 1e6;
      return num.toFixed(1) + 'MB';
    } else if (exponent >= 3) {
      num = value / 1e3;
      return num.toFixed(1) + 'KB';
    } else {
      return value + 'B';
    }
  };

  render() {
    const {} = this.props;
    let { enabledKeys } = this.state;
    return (
      <Root className={classes.divBg}>
        {/* <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}> */}
        <Grid container spacing={3} style={{ paddingTop: 16 * 2 }}>
          <ItemGrid xs={12} lg={4}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <StatsCard
                icon={Store}
                iconColor='blue'
                title='Active / Total Sites'
                description='34,245'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={
                  this.state.kpi.siteCount ? '' + (this.state.kpi.siteCount - this.state.kpi.archivedSiteCount) + '/' + this.state.kpi.siteCount : undefined
                }
              />
              <StatsCard
                icon={FileCopyOutlined}
                iconColor='blue'
                title='Sensor Docs'
                description='600'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.formatSensorCount(this.state.kpi.dataCount)}
              />
              <StatsCard
                icon={InfoOutlined}
                iconColor='blue'
                title='Companies'
                description='48/50'
                statIcon={DateRange}
                statIconColor='primary'
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.companyCount}
              />
              <StatsCard
                icon={Accessibility}
                iconColor='blue'
                title='Users'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.userCount}
              />
            </div>
            {/* <div style={{display: 'flex'}}> */}
          </ItemGrid>
          <ItemGrid xs={12} lg={4}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <PieCard
                icon={Accessibility}
                iconColor='blue'
                title='GAT Rental Rate'
                description='45/60'
                id='pie1'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                assigned={this.state.kpi.gatewayCount ? this.state.kpi.gatewayCount.assigned : 1}
                total={this.state.kpi.gatewayCount ? this.state.kpi.gatewayCount.total : 1}
              />
              <PieCard
                icon={Accessibility}
                iconColor='blue'
                title='WS Rental Rate'
                description='45/60'
                id='pie2'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                assigned={this.state.kpi.deviceCount ? this.state.kpi.deviceCount.assignedWsn : 1}
                total={this.state.kpi.deviceCount ? this.state.kpi.deviceCount.totalWsn : 1}
              />
              <PieCard
                icon={Accessibility}
                iconColor='blue'
                title='SH Rental Rate'
                description='45/60'
                id='pie3'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                assigned={this.state.kpi.deviceCount ? this.state.kpi.deviceCount.assginedHmc : 1}
                total={this.state.kpi.deviceCount ? this.state.kpi.deviceCount.totalHmc : 1}
              />
            </div>
          </ItemGrid>
          <ItemGrid xs={12} lg={4}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <StatsCard
                icon={Accessibility}
                iconColor='blue'
                title='Missing Gateways'
                description='45/60'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.missingGateways}
              />
              <StatsCard
                icon={Accessibility}
                iconColor='blue'
                title='Missing Devices'
                description='45/60'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.missingDevices}
              />
              <StatsCard
                icon={Accessibility}
                iconColor='blue'
                title='Sensor Errors'
                description='45/60'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.sensorErrors}
              />
              <StatsCard
                icon={Accessibility}
                iconColor='blue'
                title={'System Errors (prod/test)'}
                description='45/60'
                statIcon={DateRange}
                statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
                value={this.state.kpi.prod ? this.state.kpi.prod.errorCount + '/' + this.state.kpi.test.errorCount : ''}
              />
            </div>
          </ItemGrid>
        </Grid>
        {/* </div> */}
        <Grid container spacing={3}>
          <ItemGrid xs={false} sm={1} md={5}></ItemGrid>
          <ItemGrid xs={12} sm={10} md={2}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={enabledKeys.indexOf('dev') > -1} onChange={() => this.toggleStage('dev')} value='dev' style={{ color: DEV_COLOR }} />
                }
                label='Dev'
              />
              <FormControlLabel
                control={
                  <Checkbox checked={enabledKeys.indexOf('test') > -1} onChange={() => this.toggleStage('test')} value='test' style={{ color: TEST_COLOR }} />
                }
                label='Test'
              />
              <FormControlLabel
                control={
                  <Checkbox checked={enabledKeys.indexOf('prod') > -1} onChange={() => this.toggleStage('prod')} value='prod' style={{ color: PROD_COLOR }} />
                }
                label='Prod'
              />
            </div>
          </ItemGrid>
          <ItemGrid xs={false} sm={1} md={5}></ItemGrid>
        </Grid>
        <Grid container spacing={3}>
          <ItemGrid xs={12} sm={12} md={8}>
            <ChartCard
              title='ElasticSearch CPU'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.elastic}
              suffix={'%'}
              id='chartCard1'
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={4}>
            <BarCard
              title='AWS Cost (USD)'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.cost}
              prefix={'$'}
              id={'barCard1'}
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={8}>
            <ChartCard
              title='Lambda Function Invocations'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.lambda}
              id='chartCard2'
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={4}>
            <BarCard
              title='Gateway Upload Bytes'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.byteCount}
              id='barcard2'
              scaleFunction={this.byteScaling}
              suffix='MB'
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={8}>
            <ChartCard
              title='Lambda Function Duration (Total Seconds)'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.duration}
              scale={1000}
              id='chartCard3'
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={12} md={4}>
            <BarCard
              title='Outbound SMS Messages'
              statIcon={DateRange}
              statText={this.state.kpi.updatedAt ? this.timeSince(this.state.kpi.updatedAt) : 'Waiting...'}
              value={this.state.kpi.smsCount}
              id='barcard3'
              enabledKeys={enabledKeys}
            />
          </ItemGrid>
        </Grid>
      </Root>
    );
  }
}

const List = connect(mapStateToProps)(Dashboard);
export default List;
